import styled from 'styled-components';
import { useIntl } from 'react-intl';

const NavigatorWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(350px, 400px) auto;
  margin-bottom: -100px;

  @media screen and (max-width: 810px) {
    grid-template-columns: minmax(350px, 300px) auto;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    margin-bottom: ${({ hideMap }) => (hideMap ? '0' : '-110px')};
  }
`;

const MapContainer = styled.div`
  height: 800px;
  @media screen and (max-width: 600px) {
    display: ${({ hideMap }) => (hideMap ? 'none' : 'block')};
  }
  & .ais-GeoSearch-map {
    height: 800px;
  }
`;

const Navigator = styled.aside`
  display: flex;
  padding: var(--padding) var(--padding) 110px var(--padding);
  max-height: 800px;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    padding: var(--padding);
  }
`;

const NavigatorContent = styled.div`
  display: grid;
  row-gap: var(--gap-5);
  grid-auto-flow: row;
  height: min-content;
`;

const NavigatorHeader = styled.h1`
  font-size: var(--font-size-5);
  color: var(--blue-2);
  line-height: 1.2;

  @media screen and (max-width: 810px) {
    font-size: var(--font-size-4);
  }
`;

export const SectionMap = ({ navigator, list, map, hideMap }) => {
  const intl = useIntl();

  return (
    <NavigatorWrapper hideMap={hideMap}>
      <Navigator>
        <NavigatorContent>
          <NavigatorHeader>
            {intl.formatMessage({ id: 'storeFind' })}
          </NavigatorHeader>
          {navigator}
        </NavigatorContent>
        {list}
      </Navigator>
      <MapContainer hideMap={hideMap}>{map}</MapContainer>
    </NavigatorWrapper>
  );
};
