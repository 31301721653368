/* eslint-disable react/button-has-type */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { CustomMarker, GeoSearch, GoogleMapsLoader } from 'react-instantsearch-dom-maps';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { SectionMap } from '../components/layout/sections/SectionMap';
import { CustomRefinementList } from '../components/algolia/storeFinder/RefinementList';
import { CustomSearchBox } from '../components/algolia/storeFinder/SearchBox';
import { useCurrentPosition } from '../hooks/useCurrentPosition';
import { CustomHits, InfoWindow } from '../components/algolia/storeFinder/Hits';
import { PageWrapper } from '../components/layout/PageWrapper';
import {
  ButtonLocation,
  ButtonMap,
  RefinementButton,
  RefinementButtonsGrid,
} from '../components/ui/Buttons';
import { MarkerIcon, PinIcon } from '../components/vectors/Misc';
import { SmallParagraph } from '../components/styles/Typography';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

const StoreFinder = () => {
  const [aroundData, setAroundData] = useState();
  // Needs to be initalized with undefined or refineOnMapMove won't work
  const [radius, setRadius] = useState();
  const [reverseAddress, setReverseAddress] = useState('');
  const [infoWindow, setInfoWindow] = useState(null);
  const [hideMap, setHideMap] = useState(true);

  const intl = useIntl();

  const {
    getPositionData,
    positionData: { lat, lng },
  } = useCurrentPosition();

  useEffect(() => {
    (async () => {
      if (lat) {
        try {
          setAroundData(`${lat}, ${lng}`);
          setRadius(50 * 1000);
          const request = axios({
            method: 'GET',
            url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=street_address&key=${process.env.GATSBY_GOOGLE_GEOCODING}`,
          });
          const { data } = await request;
          const [address] = await data.results;
          setReverseAddress(address.formatted_address);
        } catch (err) {
          setReverseAddress('');
        }
      }
    })();
  }, [lat, lng]);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'storeFindNear' })}</title>
      </Helmet>
      <InstantSearch indexName="Stores" searchClient={searchClient}>
        <SectionMap
          hideMap={hideMap}
          navigator={
            <>
              <Configure
                aroundLatLng={aroundData}
                aroundRadius={radius}
                hitsPerPage={400}
                // eslint-disable-next-line no-unneeded-ternary
                aroundLatLngViaIP={!aroundData ? true : false}
              />
              <CustomRefinementList attribute="storeType" />
              {(!lat || radius === 'all') && <CustomSearchBox />}
              {!lat ? (
                <ButtonLocation onClick={getPositionData}>
                  {intl.formatMessage({ id: 'useMyLocation' })}
                </ButtonLocation>
              ) : (
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '25px auto',
                    alignItems: 'center',
                  }}
                >
                  <PinIcon />
                  <div>
                    <SmallParagraph css={{ lineHeight: 1 }} as="h2">
                      {intl.formatMessage({ id: 'yourLocation' })}
                    </SmallParagraph>
                    <SmallParagraph>{reverseAddress}</SmallParagraph>
                  </div>
                </div>
              )}
              <ButtonMap blue onClick={() => setHideMap(!hideMap)}>
                {intl.formatMessage({ id: hideMap ? 'showMap' : 'showList' })}
              </ButtonMap>
              {lat && (
                <RefinementButtonsGrid>
                  <RefinementButton
                    radiusMin={radius}
                    onClick={() => setRadius(5 * 1000)}
                  >
                    {intl.formatMessage({ id: 'kmRange' }, { km: 5 })}
                  </RefinementButton>
                  <RefinementButton
                    radiusMax={radius}
                    onClick={() => setRadius(50 * 1000)}
                  >
                    {intl.formatMessage({ id: 'kmRange' }, { km: 50 })}
                  </RefinementButton>
                  <RefinementButton
                    noRadius={radius}
                    onClick={() => {
                      setRadius('all');
                    }}
                  >
                    {intl.formatMessage({ id: 'all' })}
                  </RefinementButton>
                </RefinementButtonsGrid>
              )}
            </>
          }
          list={<CustomHits lat={lat} lng={lng} hideMap={hideMap} />}
          map={
            <GoogleMapsLoader apiKey={process.env.GATSBY_GOOGLE_MAPS}>
              {(google) => (
                <GeoSearch
                  enableRefineOnMapMove
                  initialZoom={10}
                  initialPosition={{
                    lat: 45.274068,
                    lng: 9.09342,
                  }}
                  mapId="84436bb6513bffbe"
                  google={google}
                >
                  {({ hits }) => (
                    <>
                      {hits.map((hit) => (
                        <CustomMarker
                          className="markerHandler"
                          key={hit.objectID}
                          hit={hit}
                          onClick={async () => {
                            if (infoWindow === hit.objectID) {
                              setInfoWindow(null);
                              return;
                            }
                            setInfoWindow(hit.objectID);
                          }}
                        >
                          {infoWindow === hit.objectID && (
                            <InfoWindow
                              closeWindow={() => setInfoWindow(null)}
                              objectId={hit.objectId}
                              storeName={hit.storeName}
                              textAddress={hit.textAddress}
                              phoneNumber={hit.phoneNumber}
                              googleMapsLink={hit.googleMapsLink}
                            />
                          )}
                          <MarkerIcon />
                        </CustomMarker>
                      ))}
                    </>
                  )}
                </GeoSearch>
              )}
            </GoogleMapsLoader>
          }
        />
      </InstantSearch>
    </>
  );
};

const StoreFinderPage = ({ pageContext }) => {
  return (
    <PageWrapper
      fullWidth
      seoTitle=""
      pageData={pageContext}
    >
      <StoreFinder />
    </PageWrapper>
  );
};
export default StoreFinderPage;
