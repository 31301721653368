/* eslint-disable no-undef */
import { useState } from 'react';

export const useCurrentPosition = () => {
  const [error, setError] = useState(null);
  const [positionData, setPositionData] = useState({
    lat: null,
    lng: null,
    accuracy: null,
  });

  const options = {
    maximumAge: 2000,
    enableHighAccuracy: true,
    timeout: 5000,
  };

  const successCallback = async (pos) => {
    const { latitude, longitude, accuracy } = await pos.coords;
    setPositionData({
      lat: latitude,
      lng: longitude,
      accuracy,
    });
  };

  const errorCallback = (err) => setError(`ERROR(${err.code}): ${err.message}`);

  const getPositionData = () =>
    navigator.geolocation.getCurrentPosition(
      successCallback,
      errorCallback,
      options
    );

  return { positionData, getPositionData, error };
};
