/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { connectHits } from 'react-instantsearch-dom';
import styled from 'styled-components';
import SimpleBar from 'simplebar-react';
import { useIntl } from 'react-intl';
import { getDistance } from '../../../functions/calculateDistance';
import { LogoSymbol } from '../../vectors/Logo';
import 'simplebar/dist/simplebar.min.css';
import { CloseIcon } from '../../vectors/Misc';

const ListContainer = styled.div`
  margin-top: var(--gap-5);
  @media screen and (max-width: 600px) {
    display: ${({ hideMap }) =>
      hideMap === true ? 'block !important' : 'none'};
  }

  & .simplebar-scrollbar::before {
    background-color: var(--gold-2);
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding-right: ${({ hitsNumber }) => hitsNumber >= 4 && 'var(--gap-5)'};
`;

const ListItem = styled.li`
  display: grid;
  grid-template-columns: 60px auto;
  column-gap: var(--gap-6);
  padding: 10px 5px 10px 5px;
  margin-bottom: 10px;
  position: relative;
  border-radius: 0 5px 5px 0;
  align-items: center;

  background: ${({ selectedHit, objectID }) =>
    selectedHit === objectID && 'var(--gold-3)'};

  &::before {
    width: 4px;
    height: 100%;
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    background: ${({ selectedHit, objectID }) =>
      selectedHit === objectID && 'var(--gold-2)'};
  }
`;

const Left = styled.div`
  display: grid;
  justify-items: center;
  grid-template-rows: min-content min-content;
  row-gap: var(--gap-7);

  & svg {
    width: 50px;
    height: 50px;
  }
`;

const Distance = styled.div`
  font-size: 11.6px;
  white-space: nowrap;
  color: var(--blue-2);
`;

const Right = styled.div`
  display: grid;
  row-gap: var(--gap-7);
  height: min-content;
  position: relative;
  align-content: center;
`;

const StoreName = styled.h1`
  font-size: var(--font-size);
  color: var(--blue-2);
  line-height: 1;
  white-space: ${({ isInfoWindow }) => isInfoWindow && 'break-spaces'};
  max-width: ${({ isInfoWindow }) => isInfoWindow && '200px'};
`;

const StoreAddress = styled.p`
  color: var(--blue-2);
  font-size: var(--font-size-micro);
  line-height: 1.2;
  white-space: ${({ isInfoWindow }) => isInfoWindow && 'break-spaces'};
`;

const ResultsCounter = styled(StoreAddress)`
  margin-bottom: var(--gap-6);

  & span {
    font-size: var(--font-size);
    font-weight: 700;
  }
`;

const StoreActions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const StoreAction = styled.a`
  font-size: var(--font-size-micro);
  color: var(--gold-2);
  text-decoration: underline;
  width: min-content;
  white-space: nowrap;
`;

export const CustomHits = connectHits(({ hits, lat, lng, hideMap }) => {
  const [selectedHit, setSelectedHit] = useState(null);

  const intl = useIntl();

  return (
    <ListContainer hideMap={hideMap}>
      {hits.length >= 1 ? (
        <ResultsCounter>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <span>{hits.length}</span> {intl.formatMessage({ id: 'results' })}
        </ResultsCounter>
      ) : (
        <ResultsCounter>
          {intl.formatMessage({ id: 'noResults' })}
        </ResultsCounter>
      )}

      <SimpleBar
        forceVisible="y"
        autoHide={false}
        style={{ maxHeight: lat ? '395px' : '425px' }}
      >
        <List hitsNumber={hits.length}>
          {hits.map(
            ({
              objectID,
              storeName,
              _geoloc: { lat: geoLat, lng: geoLng },
              textAddress,
              phoneNumber,
              googleMapsLink,
            }) => (
              <ListItem
                key={objectID}
                objectID={objectID}
                selectedHit={selectedHit}
                onMouseEnter={() => setSelectedHit(objectID)}
                onMouseLeave={() => setSelectedHit(null)}
              >
                <Left>
                  <LogoSymbol />
                  <Distance>
                    {!lat ||
                      `${
                        Math.round(
                          getDistance(geoLat, geoLng, lat, lng) * 100
                        ) / 100
                      } km`}
                  </Distance>
                </Left>
                <Right>
                  <StoreName>{storeName}</StoreName>
                  <StoreAddress>{textAddress}</StoreAddress>
                  <StoreActions>
                    {phoneNumber && (
                      <StoreAction href={`tel:${phoneNumber}`}>
                        {intl.formatMessage({ id: 'callNow' })}
                      </StoreAction>
                    )}
                    <StoreAction
                      target="_blank"
                      rel="noreferrer"
                      href={googleMapsLink}
                    >
                      {intl.formatMessage({ id: 'openGoogleMaps' })}
                    </StoreAction>
                  </StoreActions>
                </Right>
              </ListItem>
            )
          )}
        </List>
      </SimpleBar>
    </ListContainer>
  );
});

const InfoWindowShape = styled.div`
  --width: 300px;
  display: grid;
  grid-template-columns: 50px auto;
  column-gap: var(--gap-6);
  width: var(--width);
  padding: 10px;
  background: white;
  border: 2px solid var(--gold-2);
  position: absolute;
  border-radius: 10px;
  align-items: center;
  z-index: 2;
  bottom: 55px;
  left: -130px;
`;

const CloseWindow = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;

  & svg {
    width: 12px !important;
    height: 12px !important;
    & path {
      fill: var(--blue-2);
    }
  }
`;

export const InfoWindow = ({
  storeName,
  textAddress,
  phoneNumber,
  googleMapsLink,
  closeWindow,
}) => {
  return (
    <InfoWindowShape>
      <LogoSymbol />
      <Right>
        <CloseWindow onClick={closeWindow}>
          <CloseIcon />
        </CloseWindow>
        <StoreName isInfoWindow>{storeName}</StoreName>
        <StoreAddress isInfoWindow>{textAddress}</StoreAddress>
        <StoreActions>
          <StoreAction href={`tel:${phoneNumber}`}>Telefona</StoreAction>
          <StoreAction target="_blank" rel="noreferrer" href={googleMapsLink}>
            Indicazioni stradali
          </StoreAction>
        </StoreActions>
      </Right>
    </InfoWindowShape>
  );
};
