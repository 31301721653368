import { connectSearchBox } from 'react-instantsearch-dom';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { easeInOutSine } from '../../styles/animations';
import { CloseIcon } from '../../vectors/Misc';

const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
`;

const SearchInput = styled.input`
  padding: 0.4em 0.8em;
  line-height: 1;
  appearance: none;
  border: 2px solid #ced4da;
  border-radius: 5px;
  width: 100%;
  outline: none;
  transition: border-color 400ms ${easeInOutSine};
  background: white;

  &::placeholder {
    color: #868e96;
  }

  &:focus {
    border: 2px solid var(--gold-2);
  }
`;

const ResetSearch = styled.button`
  background: none;
  padding: 0;
  border: none;
  right: 10px;
  position: absolute;

  & svg path {
    fill: #ced4da;
  }
`;

const SearchBox = ({ currentRefinement, refine }) => {
  const intl = useIntl();

  return (
    <SearchWrapper>
      <SearchInput
        type="search"
        value={currentRefinement}
        autoFocus
        onChange={(event) => refine(event.currentTarget.value)}
        placeholder={intl.formatMessage({ id: 'typeAddress' })}
      />
      <ResetSearch onClick={() => refine('')}>
        <CloseIcon />
      </ResetSearch>
    </SearchWrapper>
  );
};

export const CustomSearchBox = connectSearchBox(SearchBox);
